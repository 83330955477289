.jland-banner {
    background: url(../../resources/inter-section-background.webp);
}

.jland-banner-content:first-child {
    font-weight: bold;
    font-size: 2rem;
}

@media (max-width: 660px) {
    .jland-banner-content:first-child {
        font-size: 1.5rem;
        margin: 0 1rem;
    }

    .jland-banner-content:first-child p {
        text-align: center;
    }
}
